//
// Component: Countdown
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-countdown(){}


// Item
// ========================================================================

// @mixin hook-countdown-item(){}


// Number
// ========================================================================

// @mixin hook-countdown-number(){}


// Separator
// ========================================================================

// @mixin hook-countdown-separator(){}


// Label
// ========================================================================

// @mixin hook-countdown-label(){}


// Miscellaneous
// ========================================================================

// @mixin hook-countdown-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-countdown-item(){}
// @mixin hook-inverse-countdown-number(){}
// @mixin hook-inverse-countdown-separator(){}
// @mixin hook-inverse-countdown-label(){}
