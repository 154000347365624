//
// Component: Overlay
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-overlay(){}

// Icon
// ========================================================================

// @mixin hook-overlay-icon(){}


// Style modifiers
// ========================================================================

// @mixin hook-overlay-default(){}

// @mixin hook-overlay-primary(){}


// Miscellaneous
// ========================================================================

// @mixin hook-overlay-misc(){}