//
// Component: Description list
//
// ========================================================================


// Variables
// ========================================================================

$description-list-term-font-size:                       $global-small-font-size !default;
$description-list-term-font-weight:                     normal !default;
$description-list-term-text-transform:                  uppercase !default;


// Component
// ========================================================================



// @mixin hook-description-list-description(){}


// Style modifier
// ========================================================================

// @mixin hook-description-list-divider-term(){}


// Miscellaneous
// ========================================================================

// @mixin hook-description-list-misc(){}