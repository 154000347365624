//
// Component: Pagination
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-pagination(){}


// Items
// ========================================================================



// @mixin hook-pagination-item-hover(){}

// @mixin hook-pagination-item-active(){}

// @mixin hook-pagination-item-disabled(){}


// Miscellaneous
// ========================================================================

// @mixin hook-pagination-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-pagination-item(){}
// @mixin hook-inverse-pagination-item-hover(){}
// @mixin hook-inverse-pagination-item-active(){}
// @mixin hook-inverse-pagination-item-disabled(){}