//
// Component: Link
//
// ========================================================================


// Variables
// ========================================================================


// Muted
// ========================================================================

// @mixin hook-link-muted(){}

// @mixin hook-link-muted-hover(){}


// Text
// ========================================================================

// @mixin hook-link-text(){}

// @mixin hook-link-text-hover(){}


// Heading
// ========================================================================

// @mixin hook-link-heading(){}

// @mixin hook-link-heading-hover(){}


// Reset
// ========================================================================

// @mixin hook-link-reset(){}


// Miscellaneous
// ========================================================================

// @mixin hook-link-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-link-muted(){}
// @mixin hook-inverse-link-muted-hover(){}

// @mixin hook-inverse-link-text-hover(){}

// @mixin hook-inverse-link-heading-hover(){}
