//
// Component: Tile
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-tile(){}


// Style modifiers
// ========================================================================

// @mixin hook-tile-default(){}

// @mixin hook-tile-muted(){}

// @mixin hook-tile-primary(){}

// @mixin hook-tile-secondary(){}


// Miscellaneous
// ========================================================================

// @mixin hook-tile-misc(){}