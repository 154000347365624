@import "resources/assets/sass/variables-theme.scss";
@import "resources/assets/sass/mixins-theme.scss";

@import "uikit/src/scss/uikit-theme.scss";

.en-sidebar {
	border-right: 1px solid #e5e5e5;
	width: 300px;
}
.en-container {
	position: fixed;
	bottom: 0;
	top: 80px;
	width: 100%;
}
.en-navbar-container {
	background: $global-primary-background;
	height: 80px;
	color: white;
}
.index-headline {
	font-size: 68px;
	color: $global-primary-background;
	font-weight: 100;
}
.index-subheadline {
}
.en-logo {
	font-weight: 100;
}
.en-response-code {
	
	.code {
		min-height: 100px;
	}
}
.en-spinner {
	display: none;

	&.show {
		display: inline-block;
	}
}