//
// Component: Comment
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$comment-primary-padding:                        $global-gutter !default;
$comment-primary-background:                     $global-muted-background !default;


// Component
// ========================================================================

// @mixin hook-comment(){}


// Sections
// ========================================================================

// @mixin hook-comment-body(){}

// @mixin hook-comment-header(){}


// Title
// ========================================================================

// @mixin hook-comment-title(){}


// Meta
// ========================================================================

// @mixin hook-comment-meta(){}


// Avatar
// ========================================================================

// @mixin hook-comment-avatar(){}


// List
// ========================================================================

// @mixin hook-comment-list-adjacent(){}

// @mixin hook-comment-list-sub(){}

// @mixin hook-comment-list-sub-adjacent(){}


// Style modifier
// ========================================================================




// Miscellaneous
// ========================================================================

// @mixin hook-comment-misc(){}