//
// Component: Heading
//
// ========================================================================


// Variables
// ========================================================================


// Primary
// ========================================================================

// @mixin hook-heading-primary(){}


// Hero
// ========================================================================

// @mixin hook-heading-hero(){}


// Divider
// ========================================================================

// @mixin hook-heading-divider(){}


// Bullet
// ========================================================================

// @mixin hook-heading-bullet(){}


// Line
// ========================================================================

// @mixin hook-heading-line(){}



// Miscellaneous
// ========================================================================

// @mixin hook-heading-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-heading-primary(){}

// @mixin hook-inverse-heading-hero(){}

// @mixin hook-inverse-heading-divider(){}

// @mixin hook-inverse-heading-bullet(){}

// @mixin hook-inverse-heading-line(){}
